const SectionHeading = ({ title }) => {
  return (
    <div className="waitinglist-container__top__heading">
      <p className="waitinglist-container__top__heading__text">
        {title}
      </p>
      <div className="waitinglist-container__top__heading__bar"></div>

    </div>
  )
}

export default SectionHeading