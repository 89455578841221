import Nav from '../Nav';
import "./styles.scss"

const Hero = () => {
  return (
    <div className="hero-container">
      <Nav />
      <div className="hero-container__heading">
        <h1 className="hero-container__heading__title">
          UTTA TOKEN
        </h1>
        <p className="hero-container__heading__text">
          The Beginning of a New Stable and Supercharged Wealth Era in
          Cryptocurrrency, Never Seen Before.
        </p>
        <p className="hero-container__heading__launch">
          We are Launching Soon
        </p>
      </div>
    </div>
  )
}

export default Hero