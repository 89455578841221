import './App.scss';
import Hero from './components/Hero';
import WaitingList from './components/WaitingList/index';
import HugeRewards from './components/HugeRewards/index';
import Join from './components/Join';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Hero />
      <WaitingList />
      <HugeRewards />
      <Join />
      <Footer />
    </div>
  );
}

export default App;
