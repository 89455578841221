import facebookIcon from "../../assets/FooterIcons/facebook.svg"
import instagramIcon from "../../assets/FooterIcons/instagram.svg"
import youtubeIcon from "../../assets/FooterIcons/youtube.svg"
import twitterIcon from "../../assets/FooterIcons/twitter.svg"
import pinterestIcon from "../../assets/FooterIcons/pinterest.svg"
import linkedinIcon from "../../assets/FooterIcons/in.svg"
import discordIcon from "../../assets/FooterIcons/discord.svg"
import tiktokIcon from "../../assets/FooterIcons/tiktok.svg"
import vkIcon from "../../assets/FooterIcons/VK.svg"
import redditIcon from "../../assets/FooterIcons/Reddit.svg"
import telegramIcon from "../../assets/FooterIcons/telegram.svg"
import whatsappIcon from "../../assets/FooterIcons/whatsapp.svg"
import messengerIcon from "../../assets/FooterIcons/messanger.svg"
import viberIcon from "../../assets/FooterIcons/viber.svg"
import signalIcon from "../../assets/FooterIcons/signal.svg"
import snapchatIcon from "../../assets/FooterIcons/snapchat.svg"
import lineIcon1 from "../../assets/FooterIcons/line.svg"
import lineIcon2 from "../../assets/FooterIcons/line-1.svg"
import quoraIcon from "../../assets/FooterIcons/Quora.svg"
import mediumIcon from "../../assets/FooterIcons/Medium.svg"

const linkIconDetails = [
  {
    name: "Facebook",
    icon: facebookIcon,
    link: null
  },
  {
    name: "Instagram",
    icon: instagramIcon,
    link: null
  },
  {
    name: "Youtube",
    icon: youtubeIcon,
    link: null
  },
  {
    name: "Twitter",
    icon: twitterIcon,
    link: null
  },
  {
    name: "Pinterest",
    icon: pinterestIcon,
    link: null
  },
  {
    name: "Link In",
    icon: linkedinIcon,
    link: null
  },
  {
    name: "Discord",
    icon: discordIcon,
    link: null
  },
  {
    name: "Tiktok",
    icon: tiktokIcon,
    link: null
  },
  {
    name: "VK",
    icon: vkIcon,
    link: null
  },
  {
    name: "Reddit",
    icon: redditIcon,
    link: null
  },
  {
    name: "Telegram",
    icon: telegramIcon,
    link: null
  },
  {
    name: "Whatsapp",
    icon: whatsappIcon,
    link: null
  },
  {
    name: "Messenger",
    icon: messengerIcon,
    link: null
  },
  {
    name: "Viber",
    icon: viberIcon,
    link: null
  },
  {
    name: "Signal",
    icon: signalIcon,
    link: null
  },
  {
    name: "Snapchat",
    icon: snapchatIcon,
    link: null
  },
  {
    name: "Line1",
    icon: lineIcon1,
    link: null
  },
  {
    name: "Line2",
    icon: lineIcon2,
    link: null
  },
  {
    name: "Quora",
    icon: quoraIcon,
    link: null
  },
  {
    name: "Medium",
    icon: mediumIcon,
    link: null
  }
]

export default linkIconDetails