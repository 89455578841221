import SectionHeading from "../SectionHeading"
import icon1 from "../../assets/HugeRewardsIcons/icon1.svg"
import icon2 from "../../assets/HugeRewardsIcons/icon2.svg"
import icon3 from "../../assets/HugeRewardsIcons/icon3.svg"
import Step from "./Step"
import "./styles.scss"

const HugeRewards = () => {

  const steps = [
    {
      icon: icon1,
      title: "Step 1 - Get your link",
      body: "Get your referral code Register and copy referral links or QR codes."
    },
    {
      icon: icon2,
      title: "Step 2 - Send Invitations",
      body: "Share your referral code Invite your friends to register through the link or code."
    },
    {
      icon: icon3,
      title: "Step 3 - Get Rewards",
      body: "Get your referral rewards. Get $8, Give $5 in real-time. Earn unlimited."
    }
  ]

  return (
    <div className="hugerewards-container">
      <SectionHeading title="Receive Huge Rewards" />
      <div className="hugerewards-container__steps">
        {steps.map((step) => <Step key={Math.random()} step={step} />)}
      </div>
    </div>
  )
}

export default HugeRewards