import "./styles.scss"

const Join = () => {
  return (
    <div className="join-container">
      <p className="join-container__text">
        Join and be kept updated and informed. Meanwhile, you can earn
        huge by joining, sharing and experiencing some amazing rewards for
        simply joining.
      </p>
      <a href="#waitlist" className="join-container__button">
        Join the Waitlist Now
      </a>

    </div>
  )
}

export default Join