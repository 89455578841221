import { useState } from 'react'
import waitListImage from "./waitlist-image.svg"
import rightArrowIcon from "../../assets/WaitingListIcons/Arrow - Right.svg"
import linkIconDetails from './linkIcons'

import "./styles.scss"
import SectionHeading from '../SectionHeading'
import WaitingListLinkIcon from './WaitingListIcon/index';


const WaitingList = () => {
  const [phoneNumber, setPhoneNumber] = useState("")

  const onchagePhoneNumber = (e) => {
    setPhoneNumber(e.target.value)
  }
  const formSubmit = (e) => {
    e.preventDefault()
    setPhoneNumber("")
  }


  return (
    <div className="waitinglist-container" id="waitlist">
      <div className="waitinglist-container__top">
        <SectionHeading title="Join our Waiting List" />
        <div className="waitinglist-container__top__body-text">
          We have over 1 million in our various
          global waiting list
        </div>
        <ul className="waitinglist-container__top__socials">
          {linkIconDetails.map((linkIcon) => {
            return (
              <WaitingListLinkIcon key={Math.random()} linkIcon={linkIcon}/>
            )
          })}

        </ul>
        <div className="waitinglist-container__top__form">
          <form onSubmit={formSubmit}>
            <input type="number" placeholder="Enter phone number" onChange={onchagePhoneNumber} value={phoneNumber} />
            <div className="waitinglist-container__top__form__submit" onClick={formSubmit} >
              Send <img src={rightArrowIcon} alt="" />
            </div>
          </form>
        </div>
      </div>
      <img src={waitListImage} alt="waitList" className="waitinglist-container__bottom" />

    </div>
  )
}

export default WaitingList