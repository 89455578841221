import telegramIcon from "../../assets/WaitingListIcons/Telegram.svg"
import whatsappIcon from "../../assets/WaitingListIcons/Whatsapp.svg"
import viberIcon from "../../assets/WaitingListIcons/Viber.svg"
import signalIcon from "../../assets/WaitingListIcons/Signal.svg"
import discordIcon from "../../assets/WaitingListIcons/discord.svg"
import lineIcon from "../../assets/WaitingListIcons/line.svg"
import groupmeIcon from "../../assets/WaitingListIcons/Groupme.svg"

const linkIconDetails = [
  {
    name: "Telegram",
    icon: telegramIcon,
    link: null
  },
  {
    name: "Whatsapp",
    icon: whatsappIcon,
    link: null
  }, {
    name: "Viber",
    icon: viberIcon,
    link: null
  }, {
    name: "Signal",
    icon: signalIcon,
    link: null
  },
  {
    name: "Discord",
    icon: discordIcon,
    link: null
  },

  {
    name: "Line",
    icon: lineIcon,
    link: null
  },
  {
    name: "Group me",
    icon: groupmeIcon,
    link: null
  },


]

export default linkIconDetails