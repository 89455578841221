import logo from "./Logo.png"
import "./styles.scss"

const Nav = () => {
  return (
    <div className="nav-container">
      <a href="">
        <img src={logo} alt="logo" className="nav-container__logo" />
      </a>
    </div>
  )
}

export default Nav