import "./styles.scss"
import linkIcons from "./linkIcons"
import FooterLinkIcon from "./FooterLinkIcon"

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-container__socials">
        <div className="footer-container__socials__set">
          {linkIcons.map((linkIcon) => {
            return (
              <FooterLinkIcon key={Math.random()} linkIcon={linkIcon} />
            )
          })}
        </div>
      </div>
      <p className="footer-container__text">
        We look forward to Welcoming and Rewarding you.
      </p>
    </div>
  )
}

export default Footer